import Div from '@jumbo/shared/Div';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import { postRequest } from 'backendServices/ApiCalls';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { ASSET_IMAGES } from "app/utils/constants/paths";
import { Alert, Box, Button, Card, CardActionArea, CardContent, CircularProgress, Container, Grid, Paper, Skeleton, Typography } from '@mui/material';
import Image from "material-ui-image";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Form, Formik } from 'formik';
import * as yup from "yup";
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { CheckCircle, Wallet } from '@mui/icons-material';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import EcommerceHeader from 'app/pages/ecommerce/EcommerceHeader';
import { useParams } from 'react-router-dom';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import { useJumboTheme } from '@jumbo/hooks';
import { getAssetPath } from 'app/utils/appHelpers';
import { alpha } from "@mui/material/styles";
import useStyles from '@jumbo/vendors/sweetalert2/style';


const validationSchema = yup.object({
    walletaddress: yup.string("Enter Wallet Address").required("Wallet address is required"),
    // paymentMethod: yup.string().required("Select a payment method"),
});

export default function NFT() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { loginUserData, setloginUserData } = useContext(CustomProvider);
    let userData = loginUserData;
    const [packages, setPackages] = useState([]);
    const [loading, setloading] = useState(true);
    const [selectedNft, setSelectNft] = useState({});
    const [open, setOpen] = useState(false);

    const [hasMore, setHasMore] = useState(true); // To track whether more data is available
    const [page, setPage] = useState(1);
    const [loadingMore, setLoadingMore] = useState(false);

    const handleClose = () => setOpen(false);

    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: "",
    });

    const [selectedMethod, setSelectedMethod] = useState("");
    const handleSelect = (method) => {
        setSelectedMethod(method);
    };


    const { collection } = useParams();
    const collectionName = collection.replace(/-/g, ' ');

    const useProfileLayout = () => {
        const { theme } = useJumboTheme();
        return useMemo(() => ({
            header: {
                sx: {
                    mt: -4,
                    mb: -7.25,
                    mx: { xs: -4, lg: -6 },
                    p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
                    background: `#002447 url(${getAssetPath(`${ASSET_IMAGES}/${collection}.png`, "1920x580")}) no-repeat center`,
                    backgroundSize: 'cover',
                    color: 'common.white',
                    position: 'relative',

                    '&::after': {
                        display: 'inline-block',
                        position: 'absolute',
                        content: `''`,
                        inset: 0,
                        backgroundColor: alpha(theme.palette.common.black, .65)
                    }
                }
            },
            sidebar: {
                sx: {
                    mr: 3.75,
                    width: { xs: '100%', lg: '33%' },
                    [theme.breakpoints.down('lg')]: {
                        minHeight: 0,
                        mr: 0,
                        order: 2
                    }
                }
            },
            wrapper: {
                sx: {
                    [theme.breakpoints.down('lg')]: {
                        flexDirection: 'column'
                    }
                }
            },
            main: {
                sx: {
                    [theme.breakpoints.down('lg')]: {
                        minHeight: 0
                    }
                }
            }
        }), [theme])
    }

    const profileLayout = useProfileLayout()

    const getPackagesList = () => {
        postRequest("/get-nft-store", {collection, page}, (response) => {
            setloading(false);
            setLoadingMore(false);
            if (response?.data?.status === "success") {
                const data = response?.data?.data?.map((row, index) => ({
                    id: row.id,
                    index: index + 1,
                    title: row.title,
                    collectionid: row.collectionid,
                    collectionname: row.collectionname,
                    price: row.price,
                    imgtype: row.imgtype,
                    file: row.file,
                    nftlink: row.nftlink,
                    imageURL: response?.data?.imageURL
                }));
                // setPackages(data);
                // console.log("🚀 ~ postRequest ~ data:", data)
                setPackages((prevPackages) => [...prevPackages, ...data]);
                if (data.length < 20) {
                    setHasMore(false); // No more data to load
                }
            }
        },
            (error) => {
                setloading(false);
                setLoadingMore(false);
                console.log(error?.response?.data);
            }
        );
    };
    // console.log("packages", packages)
    
    const fetchMoreData = () => {
        if (loadingMore || !hasMore) return; // Prevent fetching if already loading or no more data
        setLoadingMore(true); // Show loader when fetching more data
        setPage(prevPage => prevPage + 1);
    };

    // Scroll event listener to trigger fetching more data based on scroll position
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight && hasMore) {
                // If the user scrolls to the bottom, load more data
                fetchMoreData();
            }
        };
        
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [hasMore]);

    // Initial data load
    useEffect(() => {
        getPackagesList();
    }, [page]);

    const onSubmitForm = (data, setSubmitting) => {
        // console.log("🚀 ~ onSubmitForm ~ data:", data)
        // console.log("selectedNft", selectedNft)
        // console.log("Selected Payment Method:", selectedMethod);
        let params = {
            walletaddress: data.walletaddress,
            Nftid: selectedNft.id,
            amount: selectedNft.price,
            // paymentmethod:selectedMethod
            paymentmethod:"crypto"
        };
        // console.log("🚀 ~ onSubmitForm ~ params:", params)
        // return
        postRequest("/buy-nft-createcharge", params, (response) => {
            if (response?.data?.status === "error") {
                setalertData({
                    show: true,
                    message: response?.data?.message,
                    variant: "error",
                })
                setSubmitting(false);
            } else if (response?.data?.status === "success") {

                window.location.href = response?.data?.data?.data?.hosted_url
                setSubmitting(false);
                handleClose();
            }
            // else if (response?.data?.status === "purchased") {
            //     setalertData({
            //         show: true,
            //         message: response?.data?.message,
            //         variant: "error",
            //     });
            //     getPackagesList()
            //     setSubmitting(false);
            //     handleClose();
            // }
        },
            (error) => {
                console.log(error?.response?.data);
            }
        );
    };

    if (loading && page === 1) {
        return  <CircularProgress color="primary" />;
        // (
        //     <Div
        //         sx={{
        //             display: "flex",
        //             minWidth: 0,
        //             alignItems: "center",
        //             alignContent: "center",
        //             height: "100%",
        //             justifyContent: "center", // Align items horizontally
        //             textAlign: 'center'
        //         }}
        //     >
        //         <img src={`${ASSET_IMAGES}/logo-loading.png`} style={{ width: '60px' }} className="rotating" />

        //     </Div>
        // );
    }

    return (
        <JumboContentLayout
            header={<EcommerceHeader collection={collection} collectionName={collectionName} />}
            sidebar={''}
            layoutOptions={profileLayout}
        >
            {alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)}
            <Container>
                {/* <div className={classes.backdropBlur}></div> */}
                <Paper sx={{ backgroundColor: '#111224b5' }}>
                    <Typography
                        variant="h3"
                        color="#F39711"
                        p={2}
                        textAlign="center"
                        sx={{ color: "#fff", textTransform: 'capitalize' }}
                    >
                        {/* {collectionName} */}
                        {t("pages.title.ProductItems")}
                    </Typography>
                </Paper>

                <Grid container spacing={2} mt={3}>
                    {
                        // loginUserData?.status === 'approved' ? (
                        //     <Grid item xs={12}>
                        //     <Alert severity="info">
                        //         {t("pages.title.NftLimitMessage")}
                    
                        //     </Alert>
                        //     </Grid>
                        // )
                        // :
                        packages.length > 0 ?
                        packages.map((item, index) => {
                            let src
                            if (item.imgtype === "file") {
                                src = item.imageURL + item.file
                            } else {
                                src = item.file
                            }
                            return (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Card>
                                        <CardActionArea>
                                            <CardContent>
                                                <Image
                                                    src={src}
                                                    alt={item.title}
                                                    style={{
                                                        borderRadius: "5px",
                                                        border: "none",
                                                        boxShadow: "0px 0px 4px 0px ",
                                                    }}
                                                    loading={
                                                        <Skeleton
                                                            sx={{ bgcolor: "grey.900" }}
                                                            variant="rectangular"
                                                            width={213}
                                                            height={213}
                                                        />
                                                    }
                                                />
                                                <Typography
                                                    pt={2}
                                                    variant="h5"
                                                    fontWeight="bold"
                                                    color="darkgray"
                                                >
                                                    {item.title}
                                                </Typography>

                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ marginTop: 2 }}
                                                    onClick={() => { setOpen(true); setSelectNft(item) }}
                                                >
                                                    {/* {t("pages.title.GetNFT")} */}By Now (${item.price})
                                                </Button>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            )
                        })
                        :
                            <Alert severity="primary">
                                {t("pages.title.nocontent")}
                            </Alert>
                    }
                </Grid>

                <Div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <Div sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                border: '2px solid #000',
                                boxShadow: 24,
                                p: 4,
                            }}>
                                <Formik
                                    validateOnChange={true}
                                    enableReinitialize={true}
                                    initialValues={{
                                        walletaddress: "",
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={(data, { setSubmitting }) => {
                                        setSubmitting(true);
                                        onSubmitForm(data, setSubmitting);
                                    }}
                                >
                                    {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                                        <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                                            {/* Payment Method Buttons */}
                                            {/* <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    sx={{ fontWeight: '600', borderRadius: 2, display: 'flex', justifyContent: 'flex-start' }}
                                                    onClick={() => {
                                                        handleSelect("crypto");
                                                        setFieldValue("paymentMethod", "crypto");
                                                    }}
                                                >
                                                    <img src={`${ASSET_IMAGES}/web3/eth.png`} alt="ETH" style={{ width: '20px' }} />
                                                    <img src={`${ASSET_IMAGES}/web3/matic.png`} alt="MATIC" style={{ margin: '0 5px', width: '20px' }} />
                                                    Crypto
                                                    {selectedMethod === "crypto" && <CheckCircle sx={{ marginLeft: 'auto', color: 'white' }} />}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <LoadingButton
                                                    variant="contained"
                                                    fullWidth
                                                    sx={{my: 2, fontWeight: '600', borderRadius: 2, display: 'flex', justifyContent: 'flex-start' }}
                                                    startIcon={<Wallet />}
                                                    onClick={() => {
                                                        handleSelect("debit-credit");
                                                        setFieldValue("paymentMethod", "debit-credit");
                                                    }}
                                                >
                                                    Debit / Credit
                                                    {selectedMethod === "debit-credit" && <CheckCircle sx={{ marginLeft: 'auto', color: 'white' }} />}
                                                </LoadingButton>
                                            </Grid> */}

                                            {/* Wallet Address Field */}
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <JumboTextField
                                                        fullWidth
                                                        name="walletaddress"
                                                        label="Wallet Address For NFT"
                                                        type="text"
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{ width: { xs: "100%" }, justifyContent: "end" }}
                                                >
                                                    <LoadingButton
                                                        fullWidth
                                                        type="submit"
                                                        variant="contained"
                                                        size="large"
                                                        sx={{ mb: 3 }}
                                                        loading={isSubmitting}
                                                    >
                                                        <img src={`${ASSET_IMAGES}/web3/eth.png`} alt="ETH" style={{ width: '20px' }} />
                                                        <img src={`${ASSET_IMAGES}/web3/matic.png`} alt="MATIC" style={{ margin: '0 5px', width: '20px' }} />
                                                        Pay with Crypto
                                                    </LoadingButton>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Div>
                        </Fade>
                    </Modal>
                </Div>

                {loadingMore && <CircularProgress color="primary" sx={{ display: 'block', margin: '20px auto' }} />}
            </Container>
        </JumboContentLayout>
    )
}
