import React from "react";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import GroupsIcon from '@mui/icons-material/Groups';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import {MarkEmailRead, MoveToInbox, Quiz, ShoppingCartCheckoutRounded, SupportAgent, Wallet, WalletOutlined } from "@mui/icons-material";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ApiIcon from '@mui/icons-material/Api';
import InventoryIcon from '@mui/icons-material/Inventory';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

const menus = [
    {
        label: 'sidebar.menu.home',
        type: "section",
        children: [
            {
                uri: "/dashboard",
                label: 'sidebar.menuItem.dashboard',
                type: "nav-item",
                icon: <GraphicEqIcon sx={{fontSize: 20}}/>
            },
            {
                label: 'sidebar.menuItem.Packages',
                type: "collapsible",
                icon: <ApiIcon sx={{fontSize: 20}}/>,
                children: [

                    {
                        uri: "/packages",
                        label: "sidebar.menuItem.Packages",
                        type: "nav-item"
                    },
                    {
                        uri: "/upgrade-package",
                        label: "sidebar.menuItem.UpgradePackage",
                        type: "nav-item"
                    },
                    {
                        uri: "/package-history",
                        label: "sidebar.menuItem.History",
                        type: "nav-item"
                    },
                                        
                ]
            },

            {
                label: 'sidebar.menuItem.MarketPlace',
                type: "collapsible",
                icon: <ShoppingCartCheckoutRounded sx={{fontSize: 20}}/>,
                children: [

                    // {
                    //     uri: "/market-place",
                    //     label: "sidebar.menuItem.GetYourNFTs",
                    //     type: "nav-item"
                    // },
                    {
                        uri: "/collection",
                        label: "sidebar.menuItem.MyCollection",
                        type: "nav-item"
                    },
                                        
                ]
            },
              
            // {
            //     uri: "/wallet-transfer",
            //     label: 'Wallet Transfer',
            //     type: "nav-item",
            //     icon: <WalletOutlined sx={{fontSize: 20}}/>
            // },
              
            {
                uri: "/referrals",
                label: 'sidebar.menuItem.referrals',
                type: "nav-item",
                icon: <GroupsIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/payout",
                label: 'sidebar.menuItem.rpayout',
                type: "nav-item",
                icon: <AccountBalanceIcon sx={{fontSize: 20}}/>
            },
            {
                label: 'sidebar.menuItem.reports',
                type: "collapsible",
                icon: <GraphicEqIcon sx={{fontSize: 20}}/>,
                children: [

                    // {
                    //     uri: "/deposit-report",
                    //     label: "Deposit Report",
                    //     type: "nav-item"
                    // },

                    {
                        uri: "/payout-summary",
                        label: "sidebar.menuItem.PayoutSummary",
                        type: "nav-item"
                    },
                    {
                        uri: "/fast-start-bonus",
                        label: "pages.title.ReferralBonus",
                        type: "nav-item"
                    },
                    {
                        uri: "/matching-bonus",
                        label: "sidebar.menuItem.MatchingBonus",
                        type: "nav-item"
                    },
                    {
                        uri: "/nft-reward",
                        label: "sidebar.menuItem.ResidualBonus",
                        type: "nav-item"
                    },
                    {
                        uri: "/weak-leg-bonus",
                        label: "sidebar.menuItem.WeakLegBonus",
                        type: "nav-item"
                    },


                    // {
                    //     uri: "/pool-bonus",
                    //     label: "Pool Bonus",
                    //     type: "nav-item"
                    // },


                                        
                ]
            },
              
            {
                uri: "/binary-tree",
                label: 'sidebar.menuItem.tree',
                type: "nav-item",
                icon: <ReduceCapacityIcon sx={{fontSize: 20}}/>
            },
            {
                label: 'sidebar.menuItem.resource',
                type: "collapsible",
                icon: <PictureAsPdfIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/education-content",
                        label: 'sidebar.menuItem.education',
                        type: "nav-item",
                    },
                    {
                        uri: "/website-guide",
                        label: 'sidebar.menuItem.website',
                        type: "nav-item",
                    },
                    {
                        uri: "/pdf-file",
                        label: 'sidebar.menuItem.documents',
                        type: "nav-item",
                    },
                    // {
                    //     uri: "/changewallet",
                    //     label: 'sidebar.menuItem.changewallet',
                    //     type: "nav-item",
                    // },

                ]
            },
            {
                uri: "/support",
                label: 'sidebar.menuItem.helpcenter',
                type: "nav-item",
                icon: <SupportAgent sx={{ fontSize: 20 }} />,
                // children: [
                //     {
                //         uri: "/composemessage",
                //         label: 'sidebar.menuItem.composemessage',
                //         type: "nav-item",
                //     },
                //     {
                //         uri: "/inbox",
                //         label: 'sidebar.menuItem.inbox',
                //         type: "nav-item",
                //     },
                //     {
                //         uri: "/sentmessages",
                //         label: 'sidebar.menuItem.sent',
                //         type: "nav-item",
                //     },
                // ]
            },
            {
                uri: "/inbox",
                label: 'sidebar.menuItem.inbox',
                type: "nav-item",
                icon: <MoveToInbox sx={{ fontSize: 20 }} />,

            },

            
            {
                uri: "/faqs",
                label: 'sidebar.menuItem.faqs',
                type: "nav-item",
                icon: <Quiz sx={{fontSize: 20}}/>
            },
            
            {
                uri: "/news",
                label: 'sidebar.menuItem.CheckNews',
                type: "nav-item",
                icon: <NewspaperIcon sx={{fontSize: 20}}/>
            },

            // {
            //     uri: "/pdf-file",
            //     label: 'sidebar.menuItem.documents',
            //     type: "nav-item",
            //     icon: <NewspaperIcon sx={{fontSize: 20}}/>
            // },

            {
                uri: "/logout",
                label: 'sidebar.menuItem.logout',
                type: "nav-item",
                icon: <LogoutIcon sx={{fontSize: 20}}/>
            },

            
        ]
    },
  
];

export default menus;
